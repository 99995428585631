.summary-ideas {
    .title {
        color: #a52a2a;
    }

    .result {
        span {
            color: #c22e3c;
        }
    }

    .table {
        thead {
            tr {
                th {
                    border-top: none;
                }
            }
        }

        tbody {
            tr {
                td {
                    .icon-table {
                        color: green;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.summary-ideas-detail {
    .modal-dialog {
        .modal-content {
            .modal-header {
                background: #008019;
                color: #fff;
            }

            .modal-body {

                .table {
                    display: table;

                    tbody {
                        tr {
                            td {
                                a {
                                    text-decoration: none;
                                }

                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}