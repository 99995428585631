header {
  background: url(../../assets/image/header-banner.jpg) no-repeat top center;
}

header .logo {
  padding: 4px 0;
}

.container {
  max-width: 80% !important;
}

.header-title {
  margin-top: 10px; margin-left: 10px; font-size: 17px; font-weight: bold; color: #FFFFFF;
}
.header-title .title1{
  font-size: 14px; font-weight: bold; color: #FFFFFF;
}

.menu-height {
  min-height: 35px !important;
  padding-top: 15px;
  padding-bottom: 12px;
}

.header-container {
  background-color: #008019;
  
  .cursor-pointer {
    cursor: pointer;
    width:25%
  }

  .navbar {
    padding: 0rem 1rem;

    .navbar-brand {
      margin-right: 0;

      .logo {
        width: 3.125rem;
        object-fit: cover;
      }
    }

    ul.navbar-nav {
      li.nav-item {
        &.padding-left-menu {
          padding-left: 0.5rem;
        }

        a.nav-link {
          font-weight: 500;
          font-size: 0.875rem;
          color: #ffffff;
          text-transform: uppercase;
          padding: 0;

          @media (min-width: 1400px) {
            font-size: 0.9375rem;
          }

          &.active {
            color: #FFFF00;
          }
        }

        &.dropdown {
          .dropdown-menu {
            margin-top: 0.6875rem;
            background-color: transparent;
            border: 0;

            @media (max-width: 575px) {
              margin-top: 0;
              padding-top: 0;
            }

            @media (min-width: 768px) and (max-width: 991px) {
              margin-top: 0;
              padding-top: 0;
            }

            a {
              background-color: #008019;
              color: #ffffff;
              border-bottom: 1px solid white;
              padding: 0.5rem 1rem;
              font-size: 14px;

              &:hover {
                color: #FFFF00;
              }
            }
          }
        }
      }
    }
  }

  .dropdown-item {
    cursor: pointer;
  }
}

@media (min-width: 1400px) {
  .padding-left-menu {
    padding-left: 3rem !important;
  }
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

.modal-user-info {
  .modal-content {
    border: 0;

    .modal-header {
      background: #008019;
      color: #fff;
    }

    .modal-body {
      .img-avatar {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }

      .btn-change-avatar {
        color: #008019;
      }

      .media-file {
        position: absolute;
        top: 30px;
        left: 0;
        overflow: hidden;
        height: 100% !important;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}