.footer-container {
    background: #383838;
    color: white;
    padding: 15px 0;

    h5 {
        font-size: 1.125rem;
        font-family: 'Barlow Semi Condensed', sans-serif;
        font-weight: 600;
    }
}

.footer-link-box {
    background: #383838;
    text-align: center;
}

.footer-link {
    margin-top: 30px;
    background: #383838;
    text-align: center;
}

.footer-link a {
    color: #FFFFFF;
    margin-right: 10px;
    margin-left: 10px;
}

.footer-link a:hover {
    color: #FFFFFF;
}

.footer-copyright-box {
    background-color: #008019;
    min-height: 30px;
}

.footer-copyright {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #FFFFFF;
}

.footer-copyright a {
    color: #FFFFFF;
}

.footer-copyright a:hover {
    color: #FFFFFF;
}